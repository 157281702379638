@import "../../styles/_global.scss";

.header {
  width: 100%;
  display: flex;
  padding: 0 24px 0 0;
  background-color: $white-color;
  justify-content: space-between;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .08);

  &.fixedHeader {
    position: fixed;
    top: 0;
    z-index: 99;
    padding-right: 0;
  }
}

.headerIcon {
  height: 64px;
  padding: 0 12px;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
  box-sizing: border-box;
  color: rgba($color: $black-color, $alpha: 0.65);

  &:hover {
    background-color: $light-grey-color;
    border-bottom: 1px solid #e8e8e8;
  }

  :global(.ant-badge) {
    font-size: 18px;
  }
}

.menuBtn {
  composes: headerIcon;
  width: 64px;
}

.headerHub {
  font-size: 14px;
  font-weight: bolder;
  padding: 0 12px;
}

.headerRight {
  display: inline-flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.dropdownMenu {
  :global(.ant-dropdown-menu-item-group-list) {
    list-style: none;
    padding: 0;
    min-width: 160px;
  }

  :global(.ant-dropdown-menu-item-group-title),
  :global(.ant-dropdown-menu-item-group-list .ant-dropdown-menu-item) {
    height: 40px;
    line-height: 30px;
  }

  :global(.ant-dropdown-menu-item-group-list .ant-dropdown-menu-item) {
    padding-left: 20px;
  }
}

.noticePane {
  width: 336px;
  height: 490px;
  overflow: hidden;
  border-radius: 4px;
  background-color: $white-color;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .08);
}

.noticeTop,
.noticeBottom {
  padding: 12px;
  text-align: center;
  background-color: $white-color;
}

.noticeTop {
  color: $primary-color;
}

.noticeBottom {
  cursor: pointer;
}

.noticePaneList {
  min-height: 400px;
  max-height: 400px;
  overflow: auto;
  background-color: $light-grey-color;

  :global(.ant-list-item-content.ant-list-item-content-single) {
    padding: 0 16px;
  }

  :global(.ant-list-item-meta) {
    padding-left: 16px;
  }

  :global(.ant-skeleton-paragraph) {
    padding-left: 0;
    margin-bottom: 0;
  }

  :global(.ant-empty) {
    margin: 100px auto;
  }

  :global(.ant-list-item .ant-list-item-meta) {
    padding-left: 20px;
  }
}

.loadMore {
  padding: 8px;
  color: $primary-color;
  text-align: center;
}

.noMore {
  composes: loadMore;
  color: rgba($color: #000000, $alpha: 0.25);
}
