.wrapTaskModal {
  :global(.ant-table-cell) {
    padding: 10px 16px;
    color: #000;
    font-size: 10px;
  }

  :global(.ant-table-thead > tr > th) {
    background: #388BFF;
    color: #fff;
    font-size: 12px;
    font-weight: 600;
  }

  :global(.ant-table-tbody > tr.hasColor > td) {
    border-top: 1px solid #388BFF;
    border-bottom: 1px solid #388BFF;

  }
}
