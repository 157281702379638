@import '../../../../styles/_global.scss';

.dashboard {
  height: 240px;
  padding: 12px;
  color: #fff;
  background: rgba($color: #08132B, $alpha: .8);

  h1 {
    color: #fff;
  }
}

.panel {
  height: 100%;
  background-color: rgba($color: #526583, $alpha: .2);

  .title {
    padding: 9px 32px;
    overflow: hidden;

    &::after {
      @extend .gradientOval;
      top: 50px;
    }
  }
}
