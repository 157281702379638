@import '../../styles/_global.scss';

/***** 全局点击编辑表单基础样式 *****/
.editable-form {
  .ant-descriptions-row {
    height: 44px;
  }

  .ant-descriptions-item {
    border-radius: 4px;
    vertical-align: middle;

    &.editable-row:hover {
      background-color: $light-grey-color;
    }

    > span {
      vertical-align: middle;
    }

    .ant-descriptions-item-container .ant-descriptions-item-label,
    .ant-descriptions-item-container .ant-descriptions-item-content {
      align-items: center;
    }
  }

  .ant-descriptions-row > th,
  .ant-descriptions-row > td,
  .ant-descriptions-row > div {
    padding: 0 8px;
  }
}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 10px;
  min-height: 32px;
  min-width: 120px;

  .editable-cell-value-wrap-i {
    display: none;
    margin-left: 5px;
  }
}

.editable-row:hover .editable-cell-value-wrap {
  // border: 1px solid $grey-border-color;
  border-radius: 4px;
}

.editable-row:hover,
.editable-cell-value-wrap:hover {
  .editable-cell-value-wrap-i {
    display: inline-block;
    cursor: pointer;
  }
}
