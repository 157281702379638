@import '../../../../styles/_global.scss';

.radarStatus {
  color: #fff;
  width: 208px;
  height: 232px;
  position: relative;
  margin-bottom: 8px;
  overflow: hidden;
  background: rgba($color: #08132B, $alpha: .8);
}

.radarTop {
  width: 100%;
  height: 32px;
  position: absolute;
  top: 0;

  &::before {
    @extend .gradientOval;
    content: "";
    height: 50px;
    top: -48px;
  }

  &::after {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    z-index: 1;
    background: top center no-repeat;
    background-image: url('/images/robot-monitor/bar_texture_top.svg');
    background-size: 100% auto;
  }
}

.radarBottom {
  width: 100%;
  height: 32px;
  position: absolute;
  bottom: 0;

  &::before {
    @extend .gradientOval;
    content: "";
    height: 50px;
    top: unset;
    bottom: -50px;
  }

  &::after {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    z-index: 1;
    background: bottom center no-repeat;
    background-image: url('/images/robot-monitor/bar_texture_bottom.svg');
    background-size: 100% auto;
  }
}
