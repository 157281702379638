@import '../../../../styles/_global.scss';

.statusBar {
  color: #fff;
  height: 50px;
  width: calc(100% - 12px);
  margin: 0 !important;
  position: absolute;
  bottom: 0;
  background-color: rgba($color: #08132B, $alpha: .9);

  @include respond-to('screen-xs') {
    display: none;
  }

  :global(.ant-col) {
    display: flex;
    justify-content: space-evenly;
  }

  :global(.status-light) {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-left: 6px;
    border-radius: 50%;
    background-color: #95A2B6;
  }

  :global(.green) {
    background-color: #3DF2AA;
  }

  :global(.red) {
    background-color: #FA5783;
  }
}

.statusIcon {
  display: inline-flex;
  align-items: center;

  :global(.icon) {
    display: inline-block;
    width: 28px;
    height: 28px;
    background: center no-repeat;
    background-size: 100% 100%;
  }

  :global(.left-light) {
    background-image: url('/images/robot-monitor/left_light.svg');
  }

  :global(.motion) {
    background-image: url('/images/robot-monitor/motion.svg');
  }

  :global(.right-light) {
    background-image: url('/images/robot-monitor/right_light.svg');
  }

  :global(.camera-light) {
    background-image: url('/images/robot-monitor/camera_light.svg');
  }

  :global(.camera-user) {
    background-image: url('/images/robot-monitor/camera_user.svg');
  }

}
