@import '../../styles/_global';

.sideMenu {
  height: 100%;
  padding: 12px 0;
  overflow-y: auto;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.2);
  background-color: $layout-side-color;

  @include customize-scroll-bar();
}

.skeletonDark {
  padding: 12px;

  :global(.ant-skeleton-content .ant-skeleton-title),
  :global(.ant-skeleton-content .ant-skeleton-paragraph > li) {
    height: 20px;
  }

  &:global(.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title),
  &:global(.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph > li) {
    background: linear-gradient(90deg, #3a4856 25%, #354754 37%, #37485a 63%);
  }
}

.baseMenu {
  font-weight: bolder;
  padding-bottom: 50px;
  background-color: $layout-side-color;

  &:global(.ant-menu.ant-menu-dark) {
    background-color: $layout-side-color;
  }
}

// :global {

//   .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title,
//   .ant-menu-dark.ant-menu-vertical .ant-menu-item {
//     padding: 0 20px !important;

//     span,
//     a {
//       width: 40px;
//       height: 40px;
//       padding: 3px 9px;
//       display: inline-flex;
//       border-radius: 2px;
//       box-sizing: border-box;
//       border: 2px solid rgba($color: $white-color, $alpha: 0.1);
//       background-color: #222529;
//     }
//   }
// }
