@import '../../../styles/_global';

.orderHistory {
  :global {
    .ant-card-head {
      min-height: 44px;
      padding: 0 16px;
    }

    .ant-card-body {
      height: 480px;
      padding: 0 0 16px;
      overflow: auto;
    }
  }
}

.listItem {
  padding: 12px;

  &:hover {
    background-color: $light-grey-color;
  }
}
