@import '../../styles/_global.scss';

.topDrawer {
  position: relative;
  background-color: $white-color;
  width: 100%;
  :global(.ant-row) {
    width: 100%;
  }
  :global(.ant-form) {
    width: 100%;
  }
}

.drawer {
  position: relative;
  overflow: hidden;
  transition: height 0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  box-shadow: 0 1px 4px rgba($color: $black-color, $alpha: 0.08);
}

.clickPanel {
  width: 100%;
  padding: 12px;
  cursor: pointer;

  &:hover {
    background-color: $primary-color-light;
  }
}
