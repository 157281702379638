.copyCard {
  :global(.ant-card-body) {
    padding: 0;
  }

  :global(.ant-card) {
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    height: 130px;
    font-size: 16px;
    color: #333;
    overflow: hidden;

    &.active {
      border: 1px solid #4890f0;
    }

  }

  .cardTitle {
    background: #ecf4fd;
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    img {
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 20px;
      vertical-align: sub;
    }
  }

  .cardBody {
    padding: 30px 12px;
    line-height: 1.8;
    text-align: center;
    position: relative;

    .checkImg {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 52px;
      height: 52px;
    }
  }

}
