.logo {
  height: 50px;
  margin-bottom: 12px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;

  img:last-child {
    height: 50px;
    width: 120px;
    background: linear-gradient(-225deg, #343836 0%, #212322 100%);
  }

  img:first-child {
    height: 50px;
    width: 50px;
  }
}
