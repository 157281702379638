@import '../../../../styles/_global.scss';

$titleHeight: 32px;

.radarMap {
  width: 320px;
  height: 256px;
  opacity: 0.9;
  background: #000;
  overflow: hidden;
  transition: all .25s ease-in;

  @include respond-to('screen-xs') {
    display: none;
  }

  @include respond-to('screen-sl') {
    width: 400px;
    height: 320px;
  }
}

.title {
  position: relative;
  color: #fff;
  padding: 0 12px;
  height: $titleHeight;
  line-height: $titleHeight;
  background-image: linear-gradient(0deg, #051434 88%, #004DFF 100%);

  &::before {
    @extend .gradientOval;
    content: "";
  }

  &::after {
    position: absolute;
    left: 0;
    content: "";
    width: 100%;
    height: 100%;
    z-index: 1;
    background: top center no-repeat;
    background-image: url('/images/robot-monitor/bar_texture.svg');
    background-size: 100% auto;
  }
}

.map {
  height: calc(256px - #{$titleHeight});

  @include respond-to('screen-sl') {
    height: calc(320px - #{$titleHeight});
  }
}
